import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes, { instanceOf } from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Card } from 'react-bootstrap';
import {
  Recurrence,
  FrequencyType,
  EndingConditionType,
} from 'react-recurrence';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import paths from 'pages/Router/paths';
import { classesCleanUp, clearClassesData } from 'state/actions/classes';
import { fetchSellers } from '../../state/actions/sellers';
import { fetchCategories } from 'state/actions/classCategories';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { locationOptions } from 'components/options';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ageCategoryOptions, classTypeOptions, levelOptions } from 'data';

const ClassForm = ({ classData, action }) => {
  const { sellers, classCategories, loading } = useSelector(
    (state) => ({
      sellers: state.sellers.data,
      classCategories: state.classCategories.data,
      loading: state.classes.loading,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  const today = new Date();
  let defaultRecurrence = {
    startDate: today,
    endDate: today,
    frequency: FrequencyType.Weekly,
    numberOfRepetitions: 1,
    weekDaysRepetition: [],
    endingCondition: EndingConditionType.None,
    endingOccurrencesNumber: 0,
    isAllDay: false,
    startTime: today,
    endTime: today,
  };
  if (classData.schedule) {
    defaultRecurrence = { ...classData.schedule };
    const { startDate, endDate, startTime, endTime } = classData.schedule;
    defaultRecurrence.startDate =
      startDate && new Date(startDate.seconds * 1000);
    defaultRecurrence.endDate = endDate && new Date(endDate.seconds * 1000);
    defaultRecurrence.startTime =
      startTime && new Date(startTime.seconds * 1000);
    defaultRecurrence.endTime = endTime && new Date(endTime.seconds * 1000);
  }
  const [recurrence, setRecurrence] = useState(defaultRecurrence);

  const handleRecurrenceChange = (updatedRecurrence) => {
    setRecurrence(updatedRecurrence);
    if (updatedRecurrence.endingOccurrencesNumber == undefined) {
      updatedRecurrence.endingOccurrencesNumber = 0;
    }
    if (updatedRecurrence.numberOfRepetitions == undefined) {
      updatedRecurrence.numberOfRepetitions = 0;
    }
    classItem.schedule = updatedRecurrence;
    setClassItem(classItem);
  };

  useEffect(() => {
    dispatch(fetchCategories(true));
    dispatch(fetchSellers(true));
    if (!classData.id) dispatch(clearClassesData());
    return () => dispatch(classesCleanUp());
  }, [classData.id, dispatch]);

  const [classItem, setClassItem] = useState(classData);

  const onChangeHandler = useChangeHandler(setClassItem);
  const [validated, setValidated] = useState(false);

  const onSubmitHandler = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      dispatch(action({ ...classItem }));
    }
    setValidated(true);
  };
  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    setClassItem((prevState) => ({ ...prevState, file, photoURL: null }));
  };
  const imagePreviewUrl = !classItem.photoURL
    ? classItem.file && URL.createObjectURL(classItem.file)
    : classItem.photoURL;
  console.log(classItem);
  return (
    <Row>
      <Col lg={6}>
        <Card>
          <Card.Header className="card-header">
            <span className="icon pr-1">
              <i className="feather icon-book default" />
            </span>
            Class Information
          </Card.Header>
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={onSubmitHandler}>
              <Form.Group>
                <Form.Label>Active</Form.Label>
                <Form.Check
                  custom
                  type="switch"
                  name="active"
                  id="active"
                  checked={classItem.active}
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label htmlFor="name">Name *</Form.Label>
                <Form.Control
                  id="name"
                  type="text"
                  required
                  name="name"
                  value={classItem.name}
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label htmlFor="category">Category *</Form.Label>
                <Form.Control
                  as="select"
                  type="text"
                  value={classItem.categoryId}
                  onChange={onChangeHandler}
                  required
                  id="category"
                  name="categoryId"
                  aria-label="Select category"
                >
                  <option value="">Select category</option>
                  {Object.values(classCategories).map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select category
                </Form.Control.Feedback>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label htmlFor="category">Level *</Form.Label>
                <Form.Control
                  as="select"
                  type="text"
                  value={classItem.levelIds}
                  onChange={onChangeHandler}
                  required
                  multiple
                  id="level"
                  name="levelIds"
                  aria-label="Select level"
                >
                  <option value="">Select level</option>
                  {levelOptions.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select level
                </Form.Control.Feedback>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label htmlFor="category">Class Type *</Form.Label>
                <Form.Control
                  as="select"
                  type="text"
                  value={classItem.classTypeId}
                  onChange={onChangeHandler}
                  required
                  id="classType"
                  name="classTypeId"
                  aria-label="Select class type"
                >
                  <option value="">Select Class Type</option>
                  {classTypeOptions.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select class type
                </Form.Control.Feedback>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label htmlFor="category">Age Category *</Form.Label>
                <Form.Control
                  as="select"
                  type="text"
                  value={classItem.ageCategoryIds}
                  onChange={onChangeHandler}
                  required
                  multiple
                  id="ageCategory"
                  name="ageCategoryIds"
                  aria-label="Select age category"
                >
                  <option value="">Select age category</option>
                  {ageCategoryOptions.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select age category
                </Form.Control.Feedback>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label htmlFor="location">Location *</Form.Label>
                <Form.Control
                  as="select"
                  type="text"
                  required
                  multiple
                  value={classItem.location}
                  onChange={onChangeHandler}
                  id="location"
                  name="location"
                  aria-label="Select location"
                >
                  {Object.values(locationOptions).map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label htmlFor="seller">Seller *</Form.Label>
                <Form.Select
                  id="seller"
                  name="sellerId"
                  aria-label="Select Seller"
                  value={classItem.sellerId}
                  required
                  onChange={onChangeHandler}
                >
                  <option value="">Select Seller</option>
                  {Object.values(sellers).map((seller) => (
                    <option key={seller.id} value={seller.id}>
                      {seller.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select seller
                </Form.Control.Feedback>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label htmlFor="address">Address</Form.Label>
                <Form.Control
                  id="address"
                  type="text"
                  required
                  name="address"
                  value={classItem.address}
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label htmlFor="coach">Coach name</Form.Label>
                <Form.Control
                  id="coach"
                  type="text"
                  required
                  name="coach"
                  value={classItem.coach}
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label htmlFor="credit">Credits</Form.Label>
                <Form.Control
                  id="credit"
                  type="number"
                  required
                  name="credit"
                  value={classItem.credit}
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label htmlFor="info">Info</Form.Label>
                <CKEditor
                  editor={ClassicEditor}
                  data={classItem.info}
                  config={{
                    removePlugins: [
                      'EasyImage',
                      'ImageUpload',
                      'MediaEmbed',
                      'Table',
                    ],
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setClassItem((prevState) => ({ ...prevState, info: data }));
                  }}
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <div className="file has-name">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      accept="image/*"
                      onChange={onFileChangedHandler}
                    />
                  </label>
                  {imagePreviewUrl && (
                    <>
                      <div className="is-user-avatar image has-max-width is-aligned-center">
                        <img
                          className="user-avatar"
                          src={imagePreviewUrl}
                          alt="User profile logo preview"
                        />
                      </div>
                    </>
                  )}
                </div>
              </Form.Group>
              <hr />
              <Form.Group>
                <button
                  type="submit"
                  className={`btn btn-primary ${loading && 'is-loading'}`}
                >
                  <span>{useFormatMessage('UserForm.submit')}</span>
                </button>
                <Link to={paths.MANAGE_CLASS} className="button">
                  {useFormatMessage('UserForm.goBack')}
                </Link>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <Card.Header className="card-header">
            <span className="icon pr-1">
              <i className="feather icon-calendar default" />
            </span>
            Schedule
          </Card.Header>
          <Card.Body className="card-content">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Recurrence
                recurrence={recurrence}
                onChange={handleRecurrenceChange}
              />
            </MuiPickersUtilsProvider>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

ClassForm.propTypes = {
  classData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    info: PropTypes.string,
    location: PropTypes.array.isRequired,
    coach: PropTypes.string,
    address: PropTypes.string,
    active: PropTypes.boolean,
    sellerId: PropTypes.string.isRequired,
    credit: PropTypes.number.isRequired,
    categoryId: PropTypes.string.isRequired,
    ageCategoryId: PropTypes.string.isRequired,
    levelId: PropTypes.string.isRequired,
    classTypeId: PropTypes.string.isRequired,
    schedule: PropTypes.object,
  }),
  action: PropTypes.func.isRequired,
};

export default ClassForm;
