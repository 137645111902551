import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import paths from 'pages/Router/paths';
import { vouchersCleanUp, clearVouchersData } from 'state/actions/vouchers';
import { useChangeHandler, useFormatMessage } from 'hooks';
import DatePickerStyled from 'components/DatePicker';
import { validateId } from 'utils';

const VoucherForm = ({ voucherData, action }) => {
  const { loading, voucherList } = useSelector(
    (state) => ({
      loading: state.vouchers.loading,
      voucherList: state.vouchers.data,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [voucher, setVoucher] = useState({
    limits: {
      redeem_by: false,
      max_redemptions: false,
    },
    type: 'percent_off',
    duration: 'once',
    currency: 'sgd',
    ...voucherData,
  });
  const { id } = useParams();
  const onChangeHandler = useChangeHandler(setVoucher);
  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action({ ...voucher }));
  };

  let idInput = {
    modifier: null,
    message: { modifier: null, content: null },
  };

  const invalidId =
    (voucher.id &&
      voucherList.find(
        (item) =>
          item.id.search(new RegExp(voucher.id, 'i')) >= 0 && item.id !== id,
      )) ||
    !validateId(voucher.id);

  if (invalidId) {
    idInput = {
      modifier: 'danger',
      message: {
        modifier: 'danger',
        content: 'Duplicate id or contain special character',
      },
    };
  }
  const canSubmit = !invalidId;

  useEffect(() => {
    if (!voucherData.id) dispatch(clearVouchersData());
    return () => dispatch(vouchersCleanUp());
  }, [voucherData.id, dispatch]);

  useEffect(() => {
    if (voucher.limits.redeem_by) {
      setVoucher((prev) => ({
        ...prev,
        redeem_by: prev.redeem_by || new Date().getTime(),
      }));
    } else {
      const voucherClone = { ...voucher };
      delete voucherClone.redeem_by;
      setVoucher(voucherClone);
    }

    if (!voucher.limits.max_redemptions) {
      const voucherClone = { ...voucher };
      delete voucherClone.max_redemptions;
      setVoucher(voucherClone);
    }
  }, [voucher.limits]);

  useEffect(() => {
    if (voucher.type === 'percent_off') {
      const voucherClone = { ...voucher };
      delete voucherClone.amount_off;
      setVoucher(voucherClone);
    } else {
      const voucherClone = { ...voucher };
      delete voucherClone.percent_off;
      setVoucher(voucherClone);
    }
  }, [voucher.type]);

  return (
    <Row>
      <Col lg={12}>
        <form onSubmit={onSubmitHandler}>
          {/* Button */}
          <Form.Group>
            <button
              type="submit"
              className={`btn btn-primary ${loading && 'is-loading'}`}
              disabled={!canSubmit}
            >
              <span>{useFormatMessage('UserForm.submit')}</span>
            </button>

            <Link to={paths.MANAGE_VOUCHER} className="button">
              {useFormatMessage('UserForm.goBack')}
            </Link>
          </Form.Group>
          <br />
          {/* Name */}
          <Form.Group>
            <Form.Label htmlFor="name">Voucher Rule Name *</Form.Label>
            <Form.Control
              id="name"
              type="text"
              required
              name="name"
              value={voucher.name}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br />

          {/* Id */}
          <Form.Group>
            <Form.Label htmlFor="id"> Voucher Rule Identity *</Form.Label>
            <Form.Control
              id="id"
              type="text"
              required
              name="id"
              maxLength={200}
              minLength={1}
              value={voucher.id}
              onChange={(e) =>
                setVoucher((prev) => ({
                  ...prev,
                  id: e.target.value.toUpperCase(),
                }))
              }
              disabled={id}
            />
          </Form.Group>
          {idInput.message.content && (
            <p className={`help text-${idInput.message.modifier}`}>
              {idInput.message.content}
            </p>
          )}
          <br />

          {/* Type */}
          <Form.Group>
            <Form.Label htmlFor="type">Type</Form.Label>
            <Form.Check
              type={'radio'}
              id={`percent_off_check`}
              label={`Percentage discount`}
              value={'percent_off'}
              checked={voucher.type === 'percent_off'}
              onClick={(e) =>
                setVoucher((prev) => ({
                  ...prev,
                  type: e.target.value,
                }))
              }
              disabled={id}
            />
            <Form.Check
              type={'radio'}
              id={`amount_off_check`}
              label={`Fixed amount discount`}
              value={'amount_off'}
              checked={voucher.type === 'amount_off'}
              onClick={(e) =>
                setVoucher((prev) => ({
                  ...prev,
                  type: e.target.value,
                }))
              }
              disabled={id}
            />
          </Form.Group>

          <br />

          {voucher.type === 'percent_off' ? (
            <>
              <Form.Label htmlFor="name">Percentage off *</Form.Label>
              <InputGroup>
                <Form.Control
                  id="percent_off"
                  type="number"
                  required
                  name="percent_off"
                  min={0}
                  max={100}
                  value={voucher?.percent_off}
                  onChange={onChangeHandler}
                  disabled={id}
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </>
          ) : (
            <Form.Group>
              <Form.Label htmlFor="name">Discount amount *</Form.Label>
              <Form.Control
                id="amount_off"
                type="number"
                required
                name="amount_off"
                value={voucher?.amount_off}
                onChange={onChangeHandler}
                disabled={id}
              />
            </Form.Group>
          )}

          <br />

          {/* Redemption limits */}
          <Form.Group>
            <Form.Label htmlFor="redemption_limits">
              Redemption limits
            </Form.Label>
            {/* max_redemptions */}
            <Form.Check
              type={'checkbox'}
              id={`max_redemptions_check`}
              label={`Limit the total number of times this coupon can be redeemed`}
              checked={voucher.limits.max_redemptions}
              onClick={(e) =>
                setVoucher((prev) => ({
                  ...prev,
                  limits: {
                    ...prev.limits,
                    max_redemptions: e.target.checked,
                  },
                }))
              }
              disabled={id}
            />
            {voucher.limits.max_redemptions && (
              <Form.Group>
                <Form.Control
                  id="max_redemptions"
                  type="number"
                  required
                  name="max_redemptions"
                  value={voucher.max_redemptions}
                  onChange={onChangeHandler}
                  disabled={id}
                />
              </Form.Group>
            )}

            {/* max_redemptions */}
            <Form.Check
              type={'checkbox'}
              id={`redeem_by_check`}
              label={`The end date of this discount code (started date will be counted once this discount code is created)`}
              checked={voucher.limits.redeem_by}
              onClick={(e) =>
                setVoucher((prev) => ({
                  ...prev,
                  limits: {
                    ...prev.limits,
                    redeem_by: e.target.checked,
                  },
                }))
              }
              disabled={id}
            />
            {voucher.limits.redeem_by && (
              <Form.Group>
                <DatePickerStyled
                  setState={setVoucher}
                  showTimeSelect={true}
                  minDate={new Date()}
                  date={
                    voucher
                      ? id
                        ? new Date(voucher.redeem_by * 1000).getTime()
                        : new Date(voucher.redeem_by).getTime()
                      : new Date().getTime()
                  }
                  name="redeem_by"
                  disabled={id}
                />
              </Form.Group>
            )}
          </Form.Group>
        </form>
      </Col>
    </Row>
  );
};

VoucherForm.propTypes = {
  voucherData: PropTypes.shape({
    id: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  action: PropTypes.func.isRequired,
};

export default VoucherForm;
