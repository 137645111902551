import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { redirect } from 'react-router-dom';
import { deletePhoto, firebaseError, handleUploadPhoto } from 'utils';
import {
  doc,
  collection,
  getDocs,
  deleteDoc,
  addDoc,
  serverTimestamp,
  query,
  where,
  orderBy,
  updateDoc,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { db, functions } from 'firebase.js';
import { httpsCallable } from 'firebase/functions';

export const VOUCHER_FETCH_DATA_INIT = createAction('VOUCHER_FETCH_DATA_INIT');
export const VOUCHER_FETCH_DATA_SUCCESS = createAction(
  'VOUCHER_FETCH_DATA_SUCCESS',
);
export const VOUCHER_FETCH_DATA_FAIL = createAction('VOUCHER_FETCH_DATA_FAIL');

export const VOUCHER_DELETE_INIT = createAction('VOUCHER_DELETE_INIT');
export const VOUCHER_DELETE_SUCCESS = createAction('VOUCHER_DELETE_SUCCESS');
export const VOUCHER_DELETE_FAIL = createAction('VOUCHER_DELETE_FAIL');

export const VOUCHER_CLEAR_DATA = createAction('VOUCHER_CLEAR_DATA');

export const VOUCHER_CREATE_INIT = createAction('VOUCHER_CREATE_INIT');
export const VOUCHER_CREATE_SUCCESS = createAction('VOUCHER_CREATE_SUCCESS');
export const VOUCHER_CREATE_FAIL = createAction('VOUCHER_CREATE_FAIL');

export const VOUCHER_MODIFY_INIT = createAction('VOUCHER_MODIFY_INIT');
export const VOUCHER_MODIFY_SUCCESS = createAction('VOUCHER_MODIFY_SUCCESS');
export const VOUCHER_MODIFY_FAIL = createAction('VOUCHER_MODIFY_FAIL');

export const VOUCHER_CLEAN_UP = createAction('VOUCHER_CLEAN_UP');

export const VOUCHER_CLEAR_DATA_LOGOUT = createAction(
  'VOUCHER_CLEAR_DATA_LOGOUT',
);

export const fetchVouchers = (all = false) => {
  return async (dispatch) => {
    dispatch(VOUCHER_FETCH_DATA_INIT());

    const vouchers = [];

    try {
      let querySnapshot;
      let q;
      const ref = collection(db, 'vouchers');
      q = query(ref, orderBy('createdAt', 'desc'));
      querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        vouchers.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      return dispatch(VOUCHER_FETCH_DATA_SUCCESS({ vouchers }));
    } catch (error) {
      console.log(error);
      toastr.error('', error);
      return dispatch(VOUCHER_FETCH_DATA_FAIL({ error }));
    }
  };
};

export const deleteVoucher = (id) => {
  return async (dispatch, getState) => {
    dispatch(VOUCHER_DELETE_INIT());
    const { locale } = getState().preferences;

    const deleteVoucherTask = await deleteDoc(doc(db, 'vouchers', id));

    // Delete sub collection
    const docRef = doc(db, `vouchers/${id}`);
    const subcollectionRef = collection(docRef, 'promotions');
    const q = query(subcollectionRef);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    try {
      await Promise.all([deleteVoucherTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        VOUCHER_DELETE_FAIL({
          error: errorMessage,
        }),
      );
    }

    toastr.success('', 'The Voucher was deleted.');
    return dispatch(VOUCHER_DELETE_SUCCESS({ id }));
  };
};

export const clearVouchersData = () => {
  return (dispatch) => {
    dispatch(VOUCHER_CLEAR_DATA());
  };
};

export const clearVouchersDataLogout = () => {
  return (dispatch) => {
    dispatch(VOUCHER_CLEAR_DATA_LOGOUT());
  };
};

export const createVoucher = (data) => {
  return async (dispatch, getState) => {
    dispatch(VOUCHER_CREATE_INIT());
    const { locale } = getState().preferences;

    try {
      const voucher = {
        ...data,
      };

      const createVoucher = httpsCallable(functions, 'createVoucher');
      const res = await createVoucher(voucher);
      const { id } = res.data;
      window.location.replace(`/voucher/${id}`);

      // voucher.id = createVoucherDbTask.id;
      toastr.success('', 'Voucher created successfully');
      return dispatch(VOUCHER_CREATE_SUCCESS(voucher));
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        VOUCHER_CREATE_FAIL({
          error: errorMessage,
        }),
      );
    }
  };
};

export const modifyVoucher = (data) => {
  return async (dispatch, getState) => {
    dispatch(VOUCHER_MODIFY_INIT());
    const { locale } = getState().preferences;

    try {
      const voucherData = {
        ...data,
        modifiedAt: serverTimestamp(),
      };

      const updateVoucherDbTask = await updateDoc(
        doc(db, 'vouchers', voucherData.id),
        voucherData,
      );
      await Promise.all([updateVoucherDbTask]);
      toastr.success('', 'Voucher updated successfully');

      return dispatch(VOUCHER_MODIFY_SUCCESS(voucherData));
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        VOUCHER_MODIFY_FAIL({
          error: errorMessage,
        }),
      );
    }
  };
};

export const vouchersCleanUp = () => (dispatch) => dispatch(VOUCHER_CLEAN_UP());
